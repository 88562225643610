<template>
	<div class="wrapper wrapper-content animated fadeInRight">
		<div class="row">
			<div class="col-lg-12">
				<div class="tabs-container">
					<ul class="nav nav-tabs">
						<!-- <li><a class="nav-link active" data-toggle="tab" href="#tab-1"> Record </a></li> -->
					</ul>

					<div class="tab-content">
						<div class="tab-pane active">
							<div class="panel-body">
								<fieldset>
									<div class="form-group row">
										<label class="col-sm-2 col-form-label">#</label>
										<div class="col-sm-10">
											<input v-model="data['uid']" class="form-control" type="text" name="uid" readonly="readonly" />
											<span class="form-text"></span>
										</div>
									</div>

									<div class="hr-line-dashed"></div>

									<div class="form-group row">
										<label class="col-sm-2 col-form-label">Ticket Code</label>
										<div class="col-sm-10">
											<div class="input-group">
												<input v-model="data['ticket_code']" class="form-control" type="text" name="receipt_id" :readonly="true" />
											</div>
											<span class="form-text">{{ '' }}</span>
										</div>
									</div>

									<div class="hr-line-dashed"></div>

									<div class="form-group row">
										<label class="col-sm-2 col-form-label">Receipt ID</label>
										<div class="col-sm-10">
											<div class="input-group">
												<input v-model="data['receipt_id']" class="form-control" type="text" name="receipt_id" :readonly="true" />
												<span class="input-group-append">
													<button type="button" class="btn btn-primary" @click="onClickLinkReceipt">{{ 'Link' }}</button>
												</span>
											</div>
											<span class="form-text">{{ '' }}</span>
										</div>
									</div>

									<div class="hr-line-dashed"></div>

									<div class="form-group row">
										<label class="col-sm-2 col-form-label">출발지</label>
										<div class="col-sm-10">
											<div class="input-group">
												<input :value="senderStore" class="form-control" type="text" name="user_name" :readonly="true" />
												<span v-if="data.store_id > 0" class="input-group-append">
													<button type="button" class="btn btn-primary" @click="onClickLinkSenderStore">{{ 'Link' }}</button>
												</span>
											</div>
											<span class="form-text">{{ '' }}</span>
										</div>
									</div>

									<div class="hr-line-dashed"></div>

									<div class="form-group row">
										<label class="col-sm-2 col-form-label">도착지</label>
										<div class="col-sm-10">
											<div class="input-group">
												<input :value="recipientStore" class="form-control" type="text" name="user_name" :readonly="true" />
												<span v-if="data.receipient_store_id > 0" class="input-group-append">
													<button type="button" class="btn btn-primary" @click="onClickLinkRecipientStore">{{ 'Link' }}</button>
												</span>
											</div>
											<span class="form-text">{{ '' }}</span>
										</div>
									</div>

									<div class="hr-line-dashed"></div>

									<div class="form-group row">
										<label class="col-sm-2 col-form-label">예약 일시</label>
										<div class="col-sm-10">
											<input :value="$moment(data['datetime_issued']).format('YYYY-MM-DD HH:mm')" class="form-control" type="datetime" zone="Asia/Seoul" name="datetime_issued" :week-start="0" :placeholder="data['datetime_issued']" :disabled="true" />
											<span class="form-text"></span>
										</div>
									</div>

									<div class="hr-line-dashed"></div>

									<div class="form-group row">
										<label class="col-sm-2 col-form-label">예약 날짜</label>
										<div class="col-sm-10">
											<input :value="`${data['ticket_date_start']} ~ ${data['ticket_date_end']}`" class="form-control" type="datetime" zone="Asia/Seoul" :week-start="0" placeholder="예약 날짜" :disabled="true" />
											<span class="form-text"></span>
										</div>
									</div>

									<div class="hr-line-dashed"></div>

									<div class="form-group row">
										<label class="col-sm-2 col-form-label">Ticket Status</label>
										<div class="col-sm-10">
											<input :value="ticketStatus" class="form-control" type="text" name="status" readonly="readonly" />
											<span class="form-text"></span>
										</div>
									</div>

                  <div class="hr-line-dashed"></div>

                  <div class="form-group row">
										<label class="col-sm-2 col-form-label">Ticket Type</label>
										<div class="col-sm-10">
											<input :value="data.ticket_type" class="form-control" type="text" name="ticket_type" readonly="readonly" />
											<span class="form-text"></span>
										</div>
									</div>

                  <div class="hr-line-dashed"></div>

                  <div class="form-group row">
										<label class="col-sm-2 col-form-label">Issue Type</label>
										<div class="col-sm-10">
											<input :value="issueType" class="form-control" type="text" name="issue_type" readonly="readonly" />
											<span class="form-text"></span>
										</div>
									</div>

                  <div class="hr-line-dashed"></div>

                  <div class="form-group row">
										<label class="col-sm-2 col-form-label">Ticket Quantity</label>
										<div class="col-sm-10">
											<input :value="data.ticket_quantity" class="form-control" type="text" name="ticket_quantity" readonly="readonly" />
											<span class="form-text"></span>
										</div>
									</div>

                  <div class="hr-line-dashed"></div>

                  <div class="form-group row">
										<label class="col-sm-2 col-form-label">Ticket Over Quantity</label>
										<div class="col-sm-10">
											<input :value="data.ticket_over_quantity" class="form-control" type="text" name="ticket_over_quantity" readonly="readonly" />
											<span class="form-text"></span>
										</div>
									</div>

                  <div class="hr-line-dashed"></div>

                  <div class="form-group row">
										<label class="col-sm-2 col-form-label">물품 내용</label>
										<div class="col-sm-10">
											<input :value="data.product_data ? data.product_data.luggage_name : ''" class="form-control" type="text" name="luggage_name" readonly="readonly" />
											<span class="form-text"></span>
										</div>
									</div>

                  <div class="hr-line-dashed"></div>

                  <div class="form-group row">
										<label class="col-sm-2 col-form-label">Datetime Issued</label>
										<div class="col-sm-10">
											<input :value="data.datetime_issued ? $moment(data.datetime_issued).format('YYYY-MM-DD HH:mm:ss') : ''" class="form-control" type="text" name="datetime_issued" readonly="readonly" />
											<span class="form-text"></span>
										</div>
									</div>

                  <div class="hr-line-dashed"></div>

                  <div class="form-group row">
										<label class="col-sm-2 col-form-label">Datetime Checkin</label>
										<div class="col-sm-10">
											<input :value="data.datetime_check_in ? $moment(data.datetime_check_in).format('YYYY-MM-DD HH:mm:ss') : ''" class="form-control" type="text" name="datetime_check_in" readonly="readonly" />
											<span class="form-text"></span>
										</div>
									</div>

                  <div class="hr-line-dashed"></div>

									<div class="form-group row">
										<label class="col-sm-2 col-form-label">Datetime Approve</label>
										<div class="col-sm-10">
											<input :value="data.datetime_apporve ? $moment(data.datetime_apporve).format('YYYY-MM-DD HH:mm:ss') : ''" class="form-control" type="text" name="datetime_apporve" readonly="readonly" />
											<span class="form-text"></span>
										</div>
									</div>

                  <div class="hr-line-dashed"></div>

									<div class="form-group row">
										<label class="col-sm-2 col-form-label">Datetime Pick Up</label>
										<div class="col-sm-10">
											<input :value="data.datetime_pick_up ? $moment(data.datetime_pick_up).format('YYYY-MM-DD HH:mm:ss') : ''" class="form-control" type="text" name="datetime_pick_up" readonly="readonly" />
											<span class="form-text"></span>
										</div>
									</div>

                  <div class="hr-line-dashed"></div>

									<div class="form-group row">
										<label class="col-sm-2 col-form-label">Datetime Drop Off</label>
										<div class="col-sm-10">
											<input :value="data.datetime_drop_off ? $moment(data.datetime_drop_off).format('YYYY-MM-DD HH:mm:ss') : ''" class="form-control" type="text" name="datetime_drop_off" readonly="readonly" />
											<span class="form-text"></span>
										</div>
									</div>

                  <div class="hr-line-dashed"></div>


                  <div class="form-group row">
										<label class="col-sm-2 col-form-label">Datetime Checkout</label>
										<div class="col-sm-10">
											<input :value="data.datetime_check_out ? $moment(data.datetime_check_out).format('YYYY-MM-DD HH:mm:ss') : ''" class="form-control" type="text" name="datetime_check_out" readonly="readonly" />
											<span class="form-text"></span>
										</div>
									</div>

                  <div class="hr-line-dashed"></div>

                  <div class="form-group row">
										<label class="col-sm-2 col-form-label">Datetime Expired</label>
										<div class="col-sm-10">
											<input :value="data.dateime_expired ? $moment(data.datetime_expired).format('YYYY-MM-DD HH:mm:ss') : ''" class="form-control" type="text" name="datetime_expired" readonly="readonly" />
											<span class="form-text"></span>
										</div>
									</div>

									<div class="hr-line-dashed"></div>

									<div class="form-group row">
										<label class="col-sm-2 col-form-label">Datetime Cancelled</label>
										<div class="col-sm-10">
											<input :value="data.datetime_cancelled ? $moment(data.datetime_cancelled).format('YYYY-MM-DD HH:mm:ss') : ''" class="form-control" type="text" name="datetime_cancelled" readonly="readonly" />
											<span class="form-text"></span>
										</div>
									</div>

									<div class="hr-line-dashed"></div>

								</fieldset>

								<div class="form-group row">
									<div class="actions">
										<div class="left">
											<button class="btn btn-white btn-sm" @click="onClickCancel">Cancel</button>
										</div>

										<div class="right">
											<button v-if="data['status'] === 'ISSUED' || data['status'] === 'EXPIRED'" class="btn btn-danger btn-sm" :disabled="sending" @click="onClickCheckIn">체크인</button>
											<button v-if="data['status'] === 'DROP_OFF'" class="btn btn-danger btn-sm" :disabled="sending" @click="onClickCheckOut">체크아웃</button>
											<button v-if="data['status'] !== 'CANCELLED'" class="btn btn-info btn-sm" :disabled="sending" @click="onClickReserveCancel">예약취소</button>
                      <button v-if="data['status'] === 'CHECK_IN'" class="btn btn-info btn-sm" :disabled="sending" @click="onClickReverseStatus">상태복원</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import config from '@/config';
import Editor from '@/components/utils/Editor';
import ExtraEditor from '@/components/utils/ExtraEditor';
import ResourceEditor from '@/components/utils/ResourceEditor';
import moment from 'moment-timezone'

export default {
	data() {
		return {
			mode: 'unset',
			model: {},
			data: {},
			sending: false,
      items: [],
		};
	},
	mounted() {
		this.$data.mode = this.$route.meta.mode;
		this.$data.model = this.$route.meta.model;

		this.loadData();
	},
	watch: {
		data(value, oldValue) {},
	},
	computed: {
		senderStore(){
			let value = this.data.product_data;
			if(!value) return;

			return value.sender_store_id > 0 ? value.sender_store_name : value.sender_place
		},

		recipientStore(){
			let value = this.data.product_data;
			if(!value) return;

			return value.recipient_store_id > 0 ? value.recipient_store_name : value.recipient_place
		},
		ticketStatus(){
			const groups = [
				{ label: '전체', value: '' },
				{ label: '발급완료', value: 'ISSUED' },
				{ label: '체크인', value: 'CHECK_IN' },
				{ label: '배정중', value: 'ASSIGNING'},
				{ label: '승인됨', value: 'APPROVED'},
				{ label: '물품인수', value: 'PICK_UP'},
				{ label: '전달완료', value: 'DROP_OFF'},
				{ label: '체크아웃', value: 'CHECK_OUT' },
				{ label: '예약취소', value: 'CANCELLED' },
				{ label: '만료', value: 'EXPIRED' }
			];

			let filteredItems = groups.filter( o => o.value == this.data.status)

			return filteredItems.length > 0 ? filteredItems[0].label + " / " + filteredItems[0].value : ''
		},
		issueType(){
			const groups = [
				{ label: '서비스API', value: 'DELIVERY'},
				{ label: '오픈API', value: 'DELIVERY_O'},
			]

			let filteredItems = groups.filter( o => o.value == this.data.issue_type)
			return filteredItems.length > 0 ? filteredItems[0].label + " / " + filteredItems[0].value : ''
		}
	},
	methods: {
		loadData() {
			this.$store
				.dispatch('delivery/get', this.$route.params)
				.then((data) => {
					// data.ticket_date_start = data.ticket_date_start.replace(' ','T') + ':00'
					// data.ticket_date_end = data.ticket_date_end.replace(' ','T') + ':00'
					this.$data.data = data;
				})
				.catch((e) => {
					this.$toast.error(e.message);
				});
		},

		onClickLinkReceipt(e) {
			this.$router.push({
				name: 'ViewReceipt',
				params: { uid: this.data.receipt_id },
			});
		},

		onClickLinkUser(e) {
			this.$router.push({
				name: 'EditUser',
				params: { uid: this.data.user_id },
			});
		},

		onClickLinkSenderStore(e) {
			this.$router.push({
				name: 'EditStore',
				params: { uid: this.data.store_id },
			});
		},

		onClickLinkRecipientStore(e) {
			this.$router.push({
				name: 'EditStore',
				params: { uid: this.data.recipient_store_id },
			});
		},

		onClickCancel(e) {
			this.$router.push({
				name: this.model.views.list.name,
				params: {},
			});
		},

		onClickCheckIn() {
			this.$dialog
				.confirm(`${this.data.uid} 티켓을 체크인 처리할까요?`, {
					okText: '체크인 시각 설정',
					cancelText: 'Cancel',
				})
				.then((dialog) => {
          this.setCheckInTime();
				})
				.catch((e) => {
					this.$toast.error(e.message);
				});
    },

    setCheckInTime(){

      let options = {
        html: true,
      }

      let now = this.$moment().format("YYYY-MM-DDTHH:mm");

      let message = `<label> 체크인 시각 <input id="checkinTime" type="datetime-local" value=${now}  style="margin-left: 1rem"></label>`

      this.$dialog
				.confirm(message, options)
				.then((dialog) => {
          let value = document.getElementById('checkinTime').value;
          this.onCheckIn( value );
				})
				.catch((e) => {
					this.$toast.error(e.message);
				});
    },

		onCheckIn( value ) {
			let params = { uid: this.$route.params.uid , checkin_time : this.$moment(value).format('YYYY-MM-DD HH:mm:ss') };

			this.$data.sending = true;
			this.$store
				.dispatch('delivery/checkin', params)
				.then((data) => {
					this.$toast.success('체크인 되었습니다.');
					this.loadData();
				})
				.catch((e) => {
					this.$toast.error(e.message);
				})
				.finally(() => {
					this.$data.sending = false;
				});
		},

		onClickCheckOut() {
			this.$dialog
				.confirm(`${this.data.uid} 티켓을 체크아웃 처리할까요?`, {
					okText: '체크아웃 시각 설정',
					cancelText: 'Cancel',
				})
				.then((dialog) => {
          this.setCheckOutTime();
				})
				.catch((e) => {
					this.$toast.error(e.message);
				});
    },

    setCheckOutTime(){

      let options = {
        html: true,
      }

      let now = this.$moment().format("YYYY-MM-DDTHH:mm");

      let message = `<label> 체크아웃 시각 <input id="checkoutTime" type="datetime-local" value=${now}  style="margin-left: 1rem"></label>`

      this.$dialog
				.confirm(message, options)
				.then((dialog) => {
          let value = document.getElementById('checkoutTime').value;
          this.onCheckOut( value );
				})
				.catch((e) => {
					this.$toast.error(e.message);
				});
    },

		onCheckOut(value) {
			let params = { uid: this.$route.params.uid, checkout_time:  this.$moment(value).format('YYYY-MM-DD HH:mm:ss')};

			this.$data.sending = true;

			this.$store
				.dispatch('delivery/checkoutAfter', params)
				.then((data) => {
					this.$toast.success('체크아웃 되었습니다.');
					this.loadData();
				})
				.catch((e) => {
					this.$toast.error(e.message);
				})
				.finally(() => {
					this.$data.sending = false;
				});

		},

		onClickReserveCancel() {
			this.$dialog
				.confirm(`${this.data.uid} 티켓의 예약을 취소할까요?`, {
					okText: 'Confirm',
					cancelText: 'Cancel',
				})
				.then((dialog) => {
					this.onCancel();
				})
				.catch((e) => {
					this.$toast.error(e.message);
				});
    },

    onClickReverseStatus() {
			this.$dialog
				.confirm(`${this.data.uid} 티켓의 상태를 체크인에서 예약 완료로 복원하시겠습니까?`, {
					okText: 'Confirm',
					cancelText: 'Cancel',
				})
				.then((dialog) => {
					this.onReverseStatus();
				})
				.catch((e) => {
					this.$toast.error(e.message);
				});
		},

		onCancel() {
			let params = {
				uid: this.data.receipt_id,
			};

			this.$data.sending = true;
			this.$store
				.dispatch('receipt/cancel', params)
				.then((data) => {
					this.$toast.success('취소되었습니다.');
					this.loadData();
				})
				.catch((e) => {
					this.$toast.error(e.message);
				})
				.finally(() => {
					this.$data.sending = false;
				});
    },

    onReverseStatus(){
      let params = {
        uid : this.data.uid,
        to : 'ISSUED'
      }

      this.$data.sending = true;
			this.$store
				.dispatch('delivery/reverseStatus', params)
				.then((data) => {
					this.$toast.success('예약완료로 복원하였습니다.');
					this.loadData();
				})
				.catch((e) => {
					this.$toast.error(e.message);
				})
				.finally(() => {
					this.$data.sending = false;
				});
    },

	},
	components: {
		Editor,
		ExtraEditor,
		ResourceEditor,
	},
};
</script>
